import {commons} from './common';
import {indexs} from './index';
import {lps} from './lp';
import {vues} from './vues';

$(() => {
	vues();
	commons();
    lps();
    indexs();
});