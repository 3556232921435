export function indexs(){
    
        //VEGAS
        const target = $(".firstview");
        const photo = new Array();
        for(let i=0; i < target.find(".hide img").length; i++){
            photo[i] = target.find(".hide img").eq(i).attr("src");
        }
        const photos = Object.entries(photo).map(([src, value]) => ({'src': value}));
        target.vegas({
            overlay: false,
            timer:false,
            transitionDuration: 2000,
            delay: 10000,
            animation: 'kenburns',
            animationDuration: 20000,
            loop:false,
            slides: photos
        });
	
	  // SWIPER
	  var mySwiper = new Swiper ('.swiper-container', {
		// ここからオプション
		loop: true,
		slidesPerView: 3,
		spaceBetween: 45,
		autoplay: {
			delay: 3000,
			disableOnInteraction: true
		},
		pagination: {
		  el: '.swiper-pagination',
		},
		 breakpoints: {
			 640: {
				 slidesPerView: 1
			 }
		 }
	  })
	  
	  // INDEX PINS
	  const pins_elm = document.getElementById('index-pins');
	  const pins_child = document.getElementsByClassName('pins-txt');
	  if(pins_elm){
		  const controller = new ScrollMagic.Controller();
		  for(let i = 1; i < pins_child.length + 1; i++){
			  let trg = '#' + pins_elm.id +' p:nth-child('+ i +')';
			  new ScrollMagic.Scene({triggerElement: trg, duration: '500'})
			  .setPin(trg)
			  .setClassToggle('#' + pins_elm.id + ' *:nth-child('+ i +') p', 'active')
			  //.addIndicators({name: i + '(duration: 100%)'})
			  .addTo(controller); 
		  }

	  }
	
	  const circle_elm = document.getElementById('index-circle');
	  const circle_child = document.getElementsByClassName('pins-circle');
	  if(circle_elm){
		  const controller = new ScrollMagic.Controller();
		  for(let i = 1; i < circle_child.length + 1; i++){
			  let trg = '#' + circle_elm.id +' div:nth-child('+ i +')';
			  new ScrollMagic.Scene({triggerElement: trg, duration: '500'})
			  .setPin(trg)
			  .setClassToggle('#' + circle_elm.id + ' *:nth-child('+ i +') div', 'active')
			  //.addIndicators({name: i + '(duration: 100%)'})
			  .addTo(controller); 
		  }

	  }
	
	// MOUSE OVER
	  
}