export function vues(){
    const shiten_rest_url = '/wp-json/wp/v2/shiten';
    const shiten = {
        data: function(){
            return{
                data: [],
                flag: -1
            }
        },
        methods: {
            toggle: function(num){
				if(num == this.flag){
					this.flag = -1;
				}else{
					this.flag = num;
				}
            }  
        },
        mounted: function(){
            const $_this = this;
            axios.get(shiten_rest_url)
                .then(function (response) {
                    $_this.data = response.data;
                    console.log($_this.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        template:
        `
            <div v-cloak>
                <dl class="no-bottom">
                    <dt>支店</dt>
                    <dd>
                        <ul class="clearfix">
                            <li v-for="(items , index ) in data">
                                <a @click="toggle(index)" v-if="items.acf.color == 'red'">
									<img src="/images/aboutus/icon_01.png" alt="支店">
									<span v-html="items.title.rendered"></span>
								</a>
                                <a @click="toggle(index)" v-if="items.acf.color == 'yellow'">
									<img src="/images/aboutus/icon_02.png" alt="支店">
									<span v-html="items.title.rendered"></span>
								</a>
                                <a @click="toggle(index)" v-if="items.acf.color == 'green'">
									<img src="/images/aboutus/icon_03.png" alt="支店">
									<span v-html="items.title.rendered"></span>
								</a>
                                <a @click="toggle(index)" v-if="items.acf.color == 'blue'">
									<img src="/images/aboutus/icon_04.png" alt="支店">
									<span v-html="items.title.rendered"></span>
								</a>
                                <a @click="toggle(index)" v-if="items.acf.color == 'darkblue'">
									<img src="/images/aboutus/icon_05.png" alt="支店">
									<span v-html="items.title.rendered"></span>
								</a>
                                <a @click="toggle(index)" v-if="items.acf.color == 'purple'">
									<img src="/images/aboutus/icon_06.png" alt="支店">
									<span v-html="items.title.rendered"></span>
								</a>
                            </li>
                        </ul>
                    </dd>
                </dl>
                <dl class="skel">
                    <template v-for="(items , index ) in data" v-if="flag == index">
                    <dt>{{ items.title.rendered }}</dt>
                    <dd>
						<p  v-html="items.content.rendered"></p>
						<div class="map"  v-html="items.acf.map"></div>
					</dd>
                    </template>
                </dl>
            </div>
        `,
    }
    
	const vues = new Vue({
		el : "#WRAPPER",
		data : {
			productShow : 1
		},
		methods : {
			showsProduct : function(num){
				this.productShow = num;
			}
		},
        components: {
            'shiten-list' : shiten
        }
	});
}