import Rellax from 'rellax';

export function commons(){
    
	// Aタグクリック時のスクロール
	$('.pageup').click(function () {
		$("html,body").animate({scrollTop:0},"300");
	})
	
    //バーガーメニュー
    $("#HEADER .gnavi").on("click", function () {
         $(this).children("ul").stop().slideToggle(200);
         if ($(this).is('.addClose')) {
             $(this).removeClass('addClose');
         } else {
             $(this).addClass('addClose');
         }
    });
	
	

	$('#HEADER .menu').on('click', function () {
		$(this).toggleClass('active');
		$("#HEADER .gnavi").toggleClass('active');
	})
	$('#HEADER .gnavi a').on('click', function () {
		$('#HEADER .gnavi').toggleClass('active');
		$("#HEADER .menu").toggleClass('active');
	})
	
	const controller = new ScrollMagic.Controller();
	//アニメーション
	$(".motion-animate").each(function(){
		let s = $(this).offset().top - 300;
		let d = $(this);
		new ScrollMagic.Scene({
			triggerElement:'body',
			offset: s
		})
		.on('start', function(){
			d.addClass("active");
		})
		//.addIndicators()
		.addTo(controller);
	});

    if(document.getElementsByClassName('rellax').length > 0){
        const rellax = new Rellax('.rellax',{
            center:true
        });
    }
    if(document.getElementsByClassName('rellax-no').length > 0){
        const rellax_no = new Rellax('.rellax-no',{
            center:false
        });
    }

}